import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import MyNavbar from "../components/MyNavbar";
import WorkFilter from "../components/WorkFilter";

function Work() {
  const [screenWidth, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handler = (event) => {
      setWidth(event.target.innerWidth);
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  const data = useLocation().state;
  console.log(data);
  return (
    <>
      <WorkFilter width={screenWidth} />
      <Footer />
    </>
  );
}

export default Work;
