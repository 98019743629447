import React, { useEffect, useState } from "react";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { FadeInImg, FadeInText } from "../hook/Observers";



const tileImg = (imgName, project, navigate) => {
  let image = require(`../images/${imgName}`);

  return (
    <div className="col-sm-7 col-tile-img">
      {/* <Link
        className="my-link-view"
        to="/subpage"
        state={{ page: project }}
      > */}
      <FadeInImg click={() => navigate("/subpage",{state:{page:project}})} image={image} imgName={imgName} imgClass="tileImage-home" />
      {/* </Link> */}
    </div >
  );
};

const tileFooter = (name, category, project) => {
  let categories = "";
  for (let i = 0; i < category.length; i += 1) {
    categories += category[i];
    if (i !== category.length - 1) {
      categories += ", ";
    }
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <Link
            className="my-link-view"
            to="/subpage"
            state={{ page: project }}
          >
            <span className="tile-footer__brand">{name.toUpperCase()}</span>
          </Link>
        </div>
      </div>
      <div className="row footer-last">
        <div className="col">
          <span className="tile-footer__category">{categories}</span>
        </div>
        <div className="col">
          <Link
            className="my-link-view"
            to="/subpage"
            state={{ page: project }}
          >
            <span className="tile-footer__view">
              <FontAwesomeIcon icon={faArrowRightLong}></FontAwesomeIcon>
              {`${"       "}VIEW`}
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

const tileText = (props) => {
  return (
    <FadeInText imgText={props.imgText} brand={props.brand} category={props.category} project={props.project} tileFooter={tileFooter} />
  );
};

function ImageTile(props) {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const width = windowSize.width;

  if (width < 902) {
    return (
      <div className="row align-items-center tile-row">
        {tileImg(props.imgName, props.project, navigate)}
        {tileText(props)}
      </div>
    );
  }

  return (
    <div className="row align-items-center tile-row">
      {props.position % 2 === 0 ? tileImg(props.imgName, props.project, navigate) : tileText(props)}
      {props.position % 2 === 0 ? tileText(props) : tileImg(props.imgName, props.project, navigate)}
    </div>
  );
}

export default ImageTile;
