import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import MyNavbar from "../components/MyNavbar";
import dataJson from "../data.json";
import { FadeInImg } from "../hook/Observers";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: "Graphic Design" };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  buildServices() {
    const categories = [];
    const { filter } = dataJson.services;
    Object.keys(filter).forEach((id) => {
      categories.push(
        <>
          <div className="row services--category my-link-side">
            <span onClick={() => this.setState({ filter: id })}>
              {id.toUpperCase()}
            </span>
          </div>
        </>
      );
    });
    return categories;
  }

  getProject() {
    let img;
    const filter = dataJson.services.filter[this.state.filter];
    const imageName = filter.image;
    img = require(`../images/${imageName}`);
    return (
      <Link
        className="my-link-view"
        to="/subpage"
        state={{ page: filter.project }}
      >
        <FadeInImg image={img} key={imageName} imgClass="tileImage-services"></FadeInImg>
      </Link>
    );
  }

  render() {
    const imgName = "SayHay-Subpage-Imageblocks-Creams.jpg";
    const img = require(`../images/Subpage/Creams/${imgName}`);
    return (
      <>
        <div className="tiles">
          <div className="row tile-row">
            <div className="tile">
              <div className="row align-items-center">
                <div className="col-sm-7 col-tile-img">
                  <FadeInImg image={img} imgName={imgName} imgClass="tileImage"></FadeInImg>
                </div>
                <div className="col-sm-5 col-tile-text">
                  <div className="services text--head">PROFILE</div>
                  <div className="services text--sub-head">
                    SayHay is a creative hub specialising in brand identity, illustration, image editing & digital design.
                  </div>
                  <div className="services text">
                    Born out of a lifelong love of art and graphics, Sayhay boasts over 15 years experience across international brands, project and digital media.
                  </div>
                </div>
              </div>
              <div className="row align-items-center tile-row">
                <div className="col-sm-5">
                  <div className="row center head ">SERVICES</div>
                  <div className="row center services">
                    {this.buildServices()}
                  </div>
                </div>
                <div className="col-sm-7 col-tile-img">{this.getProject()}</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Services;
