import React from "react";
import dataJson from "../data.json";
import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MyNavbar from "./MyNavbar";
import Footer from "./Footer";
import { FadeInImg } from "../hook/Observers";

const getImageRow = (img1, img2, isLong) => {
  let image1;
  let image2;
  if (img1 !== "carousel") {
    image1 = require(`../images/${img1}`);
  }
  if (img2 !== "") {
    image2 = require(`../images/${img2}`);
  }

  if (isLong) {
    return (
      <div className="row tile-row">
        <div className="col-md-auto work-sub-long-img">
          <FadeInImg image={image1} imgName={img1} imgClass="tileImage"></FadeInImg>
        </div>
      </div>
    );
  }
  return (
    <div className="row tile-row">
      <div className="col-md-6 work-sub-img">
        <FadeInImg image={image1} imgName={img1} imgClass="tileImage"></FadeInImg>
      </div>
      {img2 === "" ? (
        ""
      ) : (
        <div className="col-md-6 work-sub-img">
          <FadeInImg image={image2} imgName={img2} imgClass="tileImage"></FadeInImg>
        </div>
      )}
    </div>
  );
};

const getRemainder = (images, subPage) => {
  const elements = [];
  let ids = [];
  let j = 3;
  let numCarousel = 0;
  if ("carousel" in subPage) {
    numCarousel = Object.keys(subPage.carousel).length;
    const carousel = getCarousel(subPage.carousel["1"], images[j]);
    elements.push(carousel);
    j = 4;
  }
  for (let i = j; i < images.length; i += 1) {
    if (subPage.images[images[i]] === "long") {
      elements.push(getImageRow(images[i], "", true));
    } else {
      ids.push(images[i]);
      if (ids.length === 2) {
        elements.push(getImageRow(ids[0], ids[1], false));
        ids = [];
      }
    }

    if (ids.length === 1 && i === images.length - 1) {
      if (numCarousel > 1) {
        elements.push(getCarousel(subPage.carousel["2"], ids[0], "right"));
      } else {
        elements.push(getImageRow(ids[0], "", false));
      }
    } else if (i === images.length - 1) {
      if (numCarousel > 1 && subPage.carousel["2"].type === "long") {
        elements.push(getCarousel(subPage.carousel["2"], "", "long"));
      }
    }
    if (numCarousel === 3 && i === images.length - 1) {
      elements.push(getCarousel(subPage.carousel["3"], "", "long"));
    }
  }

  return elements;
};

const getCarousel = (carousel, img2Name, layout = "left") => {
  const { images, type } = carousel;
  const elements = [];
  let img2 = null
  if (img2Name !== "") {
    img2 = require(`../images/${img2Name}`);
  }

  for (let i = 0; i < images.length; i++) {
    const img = require(`../images/${images[i]}`);
    elements.push(
      <Carousel.Item interval={1000}>
        <img src={img} imgName={images[i]} className="tileImage" />
      </Carousel.Item>
    );
  }

  if (layout === "right") {
    return (
      <div className="row tile-row">
        <div className="col-md-6 work-sub-img">
          <FadeInImg image={img2} imgName={img2Name} imgClass="tileImage"></FadeInImg>
        </div>
        <div className="col-md-6 work-sub-img">
          <Carousel slide={false} controls={false}>
            {elements}
          </Carousel>
        </div>
      </div>
    );
  } else if (layout === "long") {
    return (
      <div className="row tile-row">
        <div className="col-md-auto work-sub-long-img">
          <Carousel slide={false} controls={false}>
            {elements}
          </Carousel>
        </div>
      </div>
    );
  }

  return (
    <div className="row tile-row">
      <div className="col-md-6 work-sub-img">
        <Carousel slide={false} controls={false}>
          {elements}
        </Carousel>
      </div>
      <div className="col-md-6 work-sub-img">
        <FadeInImg image={img2} imgName={img2Name} imgClass="tileImage"></FadeInImg>
      </div>
    </div>
  );
};

const buildTiles = (imgId) => {
  let tiles = [];
  let subPages = dataJson.workSubPage;
  let subPage;
  Object.keys(subPages).forEach((pageId) => {
    if (subPages[pageId].id === imgId) {
      subPage = subPages[pageId];
    }
  });

  const images = [];
  Object.keys(subPage.images).forEach((subPageImgId) => {
    images.push(subPageImgId);
  });

  let image1 = require(`../images/${images[0]}`);
  let image2 = require(`../images/${images[1]}`);
  let image3 = require(`../images/${images[2]}`);

  let categories = "";
  for (let i = 0; i < subPage.category.length; i += 1) {
    categories += subPage.category[i];
    if (i !== subPage.category.length - 1) {
      categories += ", ";
    }
  }

  const elements = [];
  if ('collab' in subPage) {
    const collabText = subPage.collab.name;
    elements.push(<span>IN COLLABORATION WITH</span>)
    for (let i = 0; i < collabText.length; i += 1) {
      const siteLink = subPage.collab.link[i];
      if (siteLink === "") {
        elements.push(<div><span>{collabText[i]}</span></div>)
      } else {
        elements.push(<div className=" link-read-more"><a className="link-read-more" href={siteLink} target="_blank">{collabText[i]}</a></div>)
      }
    }
  }

  tiles.push(
    <div className="tile-row">
      <div className="row tile-row">
        <div className="col-md-6 work-sub-img">
          <FadeInImg image={image1} imgName={images[0]} imgClass="tileImage"></FadeInImg>
        </div>
        <div className="col-md-6 work-sub-img">
          <FadeInImg image={image2} imgName={images[1]} imgClass="tileImage"></FadeInImg>
        </div>
      </div>
      <div className="row tile-row">
        <div className="col-md-6 work-sub-text">
          <div className="work-sub text--head">
            {subPage.brand.toUpperCase()}
          </div>
          <div className="work-sub text"> {subPage.text}</div>
          <div className="work-sub sub-page-link"> {elements}</div>
          <div className="work-sub text--footer">SERVICES</div>
          <div className="work-sub text--footer--category ">{categories}</div>
        </div>
        <div className="col-md-6 work-sub-img">
          <FadeInImg image={image3} imgName={images[2]} imgClass="tileImage"></FadeInImg>
        </div>
      </div>
      {getRemainder(images, subPage)}
    </div >
  );
  return tiles;
};

function WorkSubPage(props) {

  const data = useLocation().state;
  console.log(data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <div>
      <div className="Home"></div>
      <div className="tiles">{buildTiles(data.page)}</div>
      <Footer />
    </div>
  );
}

export default WorkSubPage;
