import React from "react";
import logo from "../images/SayHay-Black.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function MyNavbar() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const width = windowSize.width;

  if (width > 760) {
    const data = {
      subPage: "none",
    };
    return (
        <ul className="nav justify-content-center sticky-top sayhay-nav nav-background">
          <li className="nav-item__left">
            <Link className="my-link-side" to="/work" state={data}>
              <span className="nav-item__right">Work</span>
            </Link>
          </li>
          <li>
            <Link className="my-link-home" to="/">
              <img className="logo" src={logo} alt="logo" />
            </Link>
          </li>
          <li className="nav-item__right">
            <Link className="my-link-side" to="/services">
              <span className="nav-item__right">Services</span>
            </Link>
          </li>
        </ul>
    );
  } else {
    return (
      <Navbar bg="light" expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="logo" height={80} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link className="link-mobile" to="/work">
                Work
              </Link>
              <Link className="link-mobile" to="/services">
                Services
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default MyNavbar;
