import "../CSS/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import MyNavbar from "../components/MyNavbar";
import ImageTile from "../components/ImageTile";
import dataJson from "../data.json";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { Carousel } from "react-bootstrap";

const tilesJson = dataJson.tiles;

const buildTiles = () => {
  let tiles = [];
  let cnt = 0;
  Object.keys(tilesJson).forEach((tileId) => {
    tiles.push(
      <div className="tile">
        <ImageTile
          id={`tile-${cnt}`}
          imgName={tilesJson[tileId].imageName}
          imgText={tilesJson[tileId].imageText}
          brand={tilesJson[tileId].brand}
          category={tilesJson[tileId].category}
          position={cnt}
          project={tilesJson[tileId].project}
        />
      </div>
    );
    cnt += 1;
  });
  return tiles;
};

const getCarousel = () => {
  const { left } = dataJson.homeCarousel;
  const { right } = dataJson.homeCarousel;
  const leftCarousel = [];
  const rightCarousel = [];

  for (let i = 0; i < left.length; i++) {
    const img = require(`../images/${left[i]}`);
    leftCarousel.push(
      <Carousel.Item interval={1200}>
        <img src={img} key={left[i]} className="tileImage carousel" />
      </Carousel.Item>
    );
  }

  for (let i = 0; i < right.length; i++) {
    const img = require(`../images/${right[i]}`);
    rightCarousel.push(
      <Carousel.Item interval={1500}>
        <img src={img} key={right[i]} className="tileImage carousel" />
      </Carousel.Item>
    );
  }

  return (
    <div className="row tile-row">
      <div className="col-sm-6 home-carousel">
        <Carousel fade controls={false} className="home-carousel">
          {leftCarousel}
        </Carousel>
      </div>
      <div className="col-sm-6 home-carousel">
        <Carousel fade controls={false}>
          {rightCarousel}
        </Carousel>
      </div>
    </div>
  );
}

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="Home"></div>
      <div className="tile-carousel">
        {getCarousel()}
      </div>
      <div className="tiles">{buildTiles()}</div>
      <Footer />
    </div>
  );
}

export default Home;
