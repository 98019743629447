import React from "react";
import { Link } from "react-router-dom";
import dataJson from "../data.json";

class WorkFilter extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { filter: "ALL", width: props.width };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
 
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  checkCategoriesFilter(category) {
    for (let i = 0; i < category.length; i += 1) {
      if (category[i].toUpperCase() === this.state.filter) {
        return true;
      }
    }
    return false;
  }

  buildFilter() {
    const categories = [];
    const { work } = dataJson;
    Object.keys(work).forEach((id) => {
      for (let i = 0; i < work[id].category.length; i += 1) {
        if (!categories.includes(work[id].category[i].toUpperCase())) {
          categories.push(work[id].category[i].toUpperCase());
        }
      }
    });

    const rows = [];
    let rowIdx = 0;
    rows[rowIdx] = [];
    for (let i = 0; i < categories.length; i += 1) {
      if (this.state.width > 900) {
        if (i % 3 === 0 && i !== 0) {
          rowIdx += 1;
          rows[rowIdx] = [];
        }
      } else {
        if (i % 2 === 0 && i !== 0) {
          rowIdx += 1;
          rows[rowIdx] = [];
        }
      }
      rows[rowIdx].push(categories[i]);
    }

    const elements = [];
    for (let i = 0; i < rows.length; i += 1) {
      elements.push(this.buildFilterRow(rows[i]));
    }

    return (
      <>
        <div className="row ">
          {this.state.filter === "ALL" ? (
            <span
              className="work-filter__text underline"
              onClick={() => this.setState({ filter: "ALL" })}
            >
              ALL
            </span>
          ) : (
            <span
              className="work-filter__text "
              onClick={() => this.setState({ filter: "ALL" })}
            >
              ALL
            </span>
          )}
        </div>
        <div className="row">
          <table className="table table-responsive">{elements}</table>
        </div>
      </>
    );
  }

  getImageRow(imgIds, categs, brands) {
    const elements = [];
    for (let i = 0; i < imgIds.length; i += 1) {
      console.log(imgIds[i]);
      let image = require(`../images/${imgIds[i]}`);
      const categories = categs[i];
      const brand = brands[i];
      const data = {
        page: imgIds[i],
      };
      elements.push(
        <div className="col-sm work-image__footer">
          <Link to="/subpage" state={data}>
            <img src={image} className="work--image" alt=""></img>
          </Link>
          <div className="work--image">
            <div className="work__footer-text  link-read-more">
              <Link className=" link-read-more" to="/subpage" state={data}>
                {brand}
              </Link>
            </div>
            <div className="work__footer-subtext">{categories}</div>
          </div>
        </div>
      );
    }

    return <div className="row tile-row">{elements}</div>;
  }

  buildContent() {
    const elements = [];
    let imgIds = [];
    let categs = [];
    let brands = [];
    Object.keys(dataJson.work).forEach((id) => {
      const { imageName, category, brand } = dataJson.work[id];
      let categories = "";
      for (let i = 0; i < category.length; i += 1) {
        categories += category[i];
        if (i !== category.length - 1) {
          categories += ", ";
        }
      }

      if (this.checkCategoriesFilter(category) || this.state.filter === "ALL") {
        imgIds.push(imageName);
        categs.push(categories);
        brands.push(brand);
      }
      let lastValue = dataJson.work[Object.keys(dataJson.work).pop()];

      if (this.state.width >= 1090) {
        if (
          imgIds.length === 3 ||
          (imgIds.length < 3 && lastValue.imageName === imageName)
        ) {
          elements.push(this.getImageRow(imgIds, categs, brands));
          imgIds = [];
          categs = [];
          brands = [];
        }
      } else {
        if (
          imgIds.length === 2 ||
          (imgIds.length < 2 && lastValue.imageName === imageName)
        ) {
          elements.push(this.getImageRow(imgIds, categs, brands));
          imgIds = [];
          categs = [];
          brands = [];
        }

      }
    });
    return elements;
  }

  buildFilterRow(elements) {
    const element = [];
    for (let i = 0; i < elements.length; i += 1) {
      if (this.state.filter === elements[i]) {
        element.push(
          <td>
            <span
              className="work-filter__text work-filter__spacing underline"
              onClick={() => this.setState({ filter: elements[i] })}
            >
              {elements[i]}
            </span>
          </td>
        );
      } else {
        element.push(
          <td>
            <span
              className="work-filter__text work-filter__spacing"
              onClick={() => this.setState({ filter: elements[i] })}
            >
              {elements[i]}
            </span>
          </td>
        );
      }
    }

    return <tr>{element}</tr>;
  }

  render() {
    return (
      <div className="tiles">
        <div className="row tile-row center">
          <div>{this.buildFilter()}</div>
        </div>
        {this.buildContent()}
      </div>
    );
  }
}

export default WorkFilter;
