import React, { useState, useRef, useMemo, useEffect } from "react";

export function FadeInImg(props) {
    const targetRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const callback = entries => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    }

    const options = useMemo(() => {
        return {
            root: null,
            rootMargin: "0px",
            threshold: 0.1
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options);
        const currentTarget = targetRef.current;
        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        }
    }, [targetRef, options]);

    let imgClass = `${props.imgClass} fade-in`;
    if (isVisible) {
        imgClass = `${props.imgClass} fade-in-appear`;
    }

    return (
        <>
            <img onClick={props.click} src={props.image} className={imgClass} loading="lazy" key={props.imgName} ref={targetRef}></img>
        </>
    )
}

export function FadeInText(props) {
    const targetRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const callback = entries => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    }

    const options = useMemo(() => {
        return {
            root: null,
            rootMargin: "0px",
            threshold: 0.2
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options);
        const currentTarget = targetRef.current;
        if (currentTarget) {
            observer.observe(currentTarget);
        }

        return () => {
            if (currentTarget) {
                observer.unobserve(currentTarget);
            }
        }
    }, [targetRef, options]);

    let txtClass = "col-sm-5 col-tile-text fade-in"
    if (isVisible) {
        txtClass = "col-sm-5 col-tile-text fade-in-appear"
    }

    return (
        <div className={txtClass}>
            <div className="tile-text">{props.imgText}</div>
            <div className="tile-footer" ref={targetRef}>
                {props.tileFooter(
                    props.brand,
                    props.category,
                    props.project
                )}
            </div>
        </div>
    )
}