import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

function Footer() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const width = windowSize.width;

  if (width > 900) {
    return (
      <div className="footer">
        <div className="row">
          <div className="col-6">
            <div>
              <div>For project inquiries and more information,</div>
              <div className="link-read-more">get in touch at <a className="link-read-more" to="#" onClick={(e) => {
                window.location.href = "mailto:hello@sayhay.co.uk";
                e.preventDefault();
              }}>hello@sayhay.co.uk</a></div>
            </div>
          </div>
          <div className="col-6">
            <div className="float-end text-end footer-right">
              <div>
                SayHay is a creative hub specialising in brand identity,
              </div>
              <div>illustration, digital design & image editing.</div>
            </div>
          </div>
          <div className="row spacer">
            <div className="col link-read-more"><a className="link-read-more" href={"https://www.instagram.com/sayhaydesign/"} target="_blank">Follow on <FontAwesomeIcon icon={faInstagram} /> </a></div>
            <div className="col spacer-right text-end link-read-more">
              <Link className="link-read-more" to="/services">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="footer">
      <div className="row">
        <div className="footer-mobile">
          <div>
            SayHay is a creative hub specialising in brand identity,
            illustration, digital design & image editing.
          </div>
        </div>
      </div>
      <div className="row">
        <div>
          <div>For project inquiries and more information,</div>
          <div className="link-read-more">get in touch at <a className="link-read-more" to="#" onClick={(e) => {
            window.location.href = "mailto:hello@sayhay.co.uk";
            e.preventDefault();
          }}> hello@sayhay.co.uk</a>
          </div>
        </div>
      </div>
      <div className="row spacer">
        <div className="col">Follow on <FontAwesomeIcon icon={faInstagram} /></div>
        <div className="col spacer-right text-end link-read-more">
          <Link className="link-read-more" to="/services">
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
